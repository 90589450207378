.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    margin: 0;
    padding: 3rem;
    box-sizing: border-box;
    background: url("../img/wood_background.jpeg") rgba(32, 25, 19, 0.1);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    color: white;
    text-align: center;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
    text-transform: uppercase;
}
.header__title {
    font-size: 3rem;
}
.header__adress {
    font-size: 1.5rem;
    font-weight: normal;
    text-transform: none;
}
.phone {
    color: white;
    font-size: 2rem;
    position: relative;
    align-self: center;
    text-decoration: none;
}
.phone::before {
    background: url("../img/icons/telephone.png") no-repeat;
    width: 2rem;
    height: 2rem;
    position: absolute;
    content: "";
    top: .5rem;
    left: -2.5rem;
}
.phone::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.phone:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
.main {
    background: url("../img/text-bg.svg") rgba(32,25,19, .9);
    background-size: contain;
}
@media screen and (max-width: 901px) {
    .header {
        padding: 1rem;
        background-attachment: scroll;
    }
    .header__logo {
        display: none;
    }
    .header__logo_mobile {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 7rem;
    }
    .header__title {
        margin-top: 10rem;
    }
}
@media screen and (max-width: 601px) {
    .header__logo_mobile {
        height: 5rem;
    }
    .header__title {
        font-size: 2rem;
    }


}