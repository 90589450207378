.gallery img {
    max-width: 100%;
}
.gallery {
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
@media screen and (max-width: 601px) {
    .gallery {
        padding: .5rem;
        grid-template-columns: repeat(2, 1fr);
        gap: .5rem;
    }
}
@media screen and (max-width: 401px) {
    .gallery {
        padding: .3rem;
        grid-template-columns: 1fr;
        gap: .3rem;
    }
}