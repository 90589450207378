.menu {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
}
.menu__logo {
    height: 8rem;
}
.menu__logo:active {
    transform: scale(90%);
    transition: all .3s ease-in;
}
.menu__button {
    display: none;
}
.menu__items {
    background-color: rgba(32, 25, 19, .8);
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    height: 6rem;
    align-items: center;
    box-sizing: border-box;
}
.menu__item {
    padding: .5rem 0;
}
.menu__link {
    position: relative;
    text-decoration: none;
    color: white;
}
.menu__link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.menu__link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
.menu__mobile_burger {
    background: url("../img/icons/mobile-menu-white.svg") no-repeat;
    background-size: cover;
}
.menu__mobile_close {
    background: url("../img/icons/mobile-menu-close-white.svg") no-repeat;
    background-size: cover;
}

@media screen and (max-width: 901px) {
    .menu {
        margin: -1rem;
        background: rgb(32,25,19);
        height: 7rem;
        opacity: .98;
        position: fixed;
        width: 100%;
        z-index: 1000;
        border-bottom: 1px solid white;
    }
    .menu__logo {
        display: block;
        position: absolute;
        top: 0;
        left: 1rem;
        height: 7rem;
    }
    .menu_desktop {
        display: none;
    }
    .menu__items {
        flex-direction: column;
        padding: 1rem;
        height: auto;
        position: relative;
        background: rgb(32,25,19);
        margin-top: 7.1rem;
        border-bottom: 1px solid white;
    }
    .menu__items_show {
        right: 0;
        animation: fade-in 0.6s ease-out;
    }
    .menu__items_hide {
        right: -1000px;
        animation: fade-out 0.6s ease-in;
    }
    .menu__items_visibility {
        visibility: hidden;
    }
    .menu__button {
        height: 4rem;
        width: 4rem;
        border: none;
        position: absolute;
        right: 1rem;
        top: 1.5rem;
        cursor: pointer;
        display: block;
        background-color: transparent;
    }
    .menu__link {
        -webkit-tap-highlight-color: #e32a2a;
    }
    .menu__link:hover {
        text-decoration: none;
    }
}
@media screen and (max-width: 601px) {
    .menu {
        height: 6.5rem;
    }
    .menu__items {
        margin-top: 6.6rem;
    }
    .menu__logo {
        height: 6rem;
    }
}

@keyframes fade-in {
    0% { opacity: 0; right:-1000px;}
    100% { opacity: 1; right: 0px; }
}
@keyframes fade-out {
    0% { opacity: 1; right: 0px; }
    100%{ opacity: 0; right:-1000px;}
}