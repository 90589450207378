.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,.6);
    box-sizing: border-box;
    margin: 0;
    overflow: hidden;
}
.window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: white;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
    border-radius: 3px;
    padding: .6rem;
    box-sizing: border-box;
    overflow: hidden;
    animation: popup 0.6s ease-in;
}
.window__button_close {
    background: url("../../img/icons/mobile-menu-close-white.svg") no-repeat;
    background-color: rgba(0,0,0,.6);
    background-size: cover;
    border: none;
    cursor: pointer;
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: .6rem;
    right: .6rem;
}
.modal {
}
@media screen and (max-width: 901px) {
    .window {
        width: 80vw;
        height: calc(100vh - 8rem);
        z-index: 10;
        top: 7.5rem;
        left: 50%;
        transform: translate(-50%);
    }
}
@media screen and (max-width: 601px) {
    .window {
        width: 100vw;
        height: calc(100vh - 6.5rem);
        z-index: 10;
        top: 6.5rem;
        left: 0;
        transform: none;
    }
}

@keyframes popup {
    0% { opacity: 0;}
    100% { opacity: 1;}
}