.footer {
    background: url("../img/text-bg.svg") rgba(32,25,19, .9);
    background-size: contain;
    color: white;
    padding: 1rem;
    text-align: center;
}
.footer__map {
    height: 480px;
}
@media screen and (max-width: 601px) {
    .footer__map {
        height: 300px;
    }
}
