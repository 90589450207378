.page {
    color: white;
    padding: 1rem;
    text-align: center;
    min-height: 80vh;
    box-shadow: 2px -2px 10px 3px rgba(32, 25, 19, 1);
}
.page__list {
    padding: 0;
    list-style: none;
}
.page__list-item {
    padding: .5rem;
}
.page__header {
    font-size: 2rem;
}
.page__sub-header {
    font-size: 1.5rem;
    margin: 2rem 0 .5rem 0 ;
}
.page__sub-header_center {
    text-align: center;
}
.page__header-underlined {
    text-decoration: underline;
}
.page__link {
    color: white;
}
.page__link:hover {
    color: #e32a2a;
}
#content {
    height: 0;
    margin-top: 0rem;
    position: absolute;
}
@media screen and (max-width: 901px) {
    #content {
        margin-top: -6rem;
    }
}