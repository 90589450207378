.preloader {
    padding: 5rem 0;
    height: 100%;
    width: 100%;
    position: relative;
}
.circle-preloader {
    display: block;
    width: 50px;
    height: 50px;
    border: 4px solid #444;
    border-bottom-color: #d2d2d2;
    border-radius: 50%;
    margin: auto;
    animation: spin .9s infinite linear;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}