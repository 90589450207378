.App {
  padding: 0;
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  overflow-x: hidden;
}
.stop-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}