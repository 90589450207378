.preloader {
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, .7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
	opacity: 1;
	animation: preloader 1s ease-in;
}
.circle-preloader {
	display: block;
	width: 50px;
	height: 50px;
	border: 4px solid #444;
	border-bottom-color: #d2d2d2;
	border-radius: 50%;
	margin: auto;
	animation: spin .9s infinite linear;
}


@keyframes preloader {
	0% { opacity: 1; }
	100% { opacity: 0; }
}